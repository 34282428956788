import React from 'react'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Preisrechner from '../components/Preisrechner'
import PageBody from '../components/PageBody'
import GreenLine from '../components/GreenLine'
import SEO from '../components/SEO'
import TextContainer from '../components/TextContainer'
import '../styles/preisrechner.css'
import '../styles/perimeter.css'
import ScriptTag from 'react-script-tag';
import { withPrefix, Link } from "gatsby"


const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle, headerImage } = data.contentfulPage;

  return (
    <Layout>

      <SEO postNode={postNode} pagePath="contact" customTitle />
      <GreenLine backgroundColor={'rgba(0,0,0,0)'} />

      <link rel="stylesheet" href="//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css" />
      <ScriptTag src="https://code.jquery.com/jquery-1.12.4.js"/>
      <ScriptTag src="https://code.jquery.com/ui/1.12.1/jquery-ui.js"/>
      <ScriptTag type="text/javascript" src="https://maps.google.com/maps/api/js?v=3.17&amp;key=AIzaSyCWFg1uRh18GZOYLIjI-Z2BFreBQV6x_r0&amp;libraries=drawing,places,geometry" />

      <Preisrechner/>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "preise" }) {
      id
      title
      subtitle
      headerImage {
        id
        fluid(quality: 100, maxWidth: 1920, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          base64
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt